<template>
  <div>
    <v-chip
      v-if="fechaDesinstalacion"
      small
      label
      :color="MATERIAL_SISTEMA.colores.desinstalado"
      dark
      class="mr-1"
    >
      DESINSTALADO
    </v-chip>
  </div>
</template>

<script>
import { MATERIAL_SISTEMA } from '@/utils/consts'

export default {
  props: {
    fechaDesinstalacion:{
      type: Date,
      default: function () { return new Date() },
    },
  },
  data () {
    return {
      MATERIAL_SISTEMA,
    }
  },
}
</script>
