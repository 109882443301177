import { APIFilter } from '@/utils/api'

export default {
  async selectMaterialSistema (Vue, filter, search, sorter, page, idsubsis) {
    const apiFilter = new APIFilter()
      .addExact('idsubsis', idsubsis)
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.materialSistema.searchFilter(search))
    }
    const resp = await Vue.$api.call(
      'materialSistema.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectMaterialSistemaRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idmaterial_sistema', pks)
    const resp = await Vue.$api.call('materialSistema.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
